.multipleImages {
  &:global(.coloredModule) {
    padding: var(--spacing-10) var(--spacing-3);

    @media (--tablet) {
      padding: var(--spacing-12) var(--spacing-5);
    }

    @media (--desktop) {
      padding: var(--spacing-18) var(--spacing-6);
    }
  }

  & .row {
    row-gap: var(--spacing-4);
  }

  & [class^='ImageElement-module_wrapper'] {
    margin: unset;
    width: unset;
  }
}

.background {
  background-color: var(--color-primary-50);
}
